<template>
  <v-app ma-0 pa-0>
    <div class="achtergrond">
      <v-main>
        <transition
          enter-active-class="animate__animated animate__slideInDown"
          appear
        >
          <router-view />
        </transition>
      </v-main>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  created() {
    this.tokkie = this.$route.query.name;
    localStorage.setItem("tokkie", JSON.stringify(this.tokkie));
  }
};
</script>
<style>
.v-card__text,
.v-card__title {
  word-break: normal !important; /* maybe !important  */
}

.achtergrond {
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 100vh;
  /*background-color: #003895;*/
}

@media (min-width: 200px) {
  p {
    font-size: 0.8em;
  }

  h1 {
    font-size: 1.6em;
    line-height: 1.5;
  }

  h2 {
    font-size: 1.4em;
    line-height: 1.5;
  }

  h3 {
    font-size: 0.94em;
    line-height: 1.5;
  }
}

@media (min-width: 768px) {
  p {
    font-size: 1em;
    line-height: 1.5;
  }

  h1 {
    font-size: 1.7em;
    line-height: 1.5;
  }

  h2 {
    font-size: 1.5em;
    line-height: 1.5;
  }

  h3 {
    font-size: 1.2em;
    line-height: 1.5;
  }
}

@media (min-width: 1200px) {
  p {
    font-size: 1.2em;
    line-height: 1.5;
  }

  h1 {
    font-size: 2em;
    line-height: 1.5;
  }

  h2 {
    font-size: 1.7em;
    line-height: 1.5;
  }

  h3 {
    font-size: 1.5em;
    line-height: 1.5;
  }
}
</style>
