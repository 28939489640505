<template>
  <Start></Start>
</template>

<script>
// import Start from ",/Start";
import Start from "@/views/Start";

export default {
  components: {
    Start
  }
};
</script>
