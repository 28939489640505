<template>
  <div>
    <v-app-bar shrink-on-scroll app>
      <v-app-bar-title>
        <v-img
          :src="
            require(`@/assets/logo_${
              $vuetify.theme.dark ? 'light' : 'dark'
            }.png`)
          "
          width="100px"
        />
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="toggle_dark_mode">
        <v-icon color="roodt">mdi-theme-light-dark</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>
<script>
import router from "../../router";

export default {
  name: "Toolbar.vue",

  data() {
    return {};
  },
  computed: {
    auth() {
      return this.$store.getters.isAuthenticated;
      // eslint-disable-next-line no-unreachable
      const theme = localStorage.getItem("dark_theme");
      if (theme) {
        if (theme === "true") {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.$vuetify.theme.dark = true;
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.$vuetify.theme.dark = false;
        }
      } else if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.$vuetify.theme.dark = true;
        localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
      }
    }
  },
  methods: {
    toggle_dark_mode: function() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
    logout() {
      let data1 = null;
      // eslint-disable-next-line
      console.log("logout");
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("wachtwoord");
      this.$store.commit("change", data1);
      router.replace("login");
    }
  }
};
</script>
