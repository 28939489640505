<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <toolbar></toolbar>
    <div class="container breedte">
      <div class="row">
        <div class="col-sm-6 col-12 ">
          <div class="v-sheet pl-0 pr-0">
            <div class="container ml-0 pl-1">
              <v-card-text class=" body-1 spec--text">
                Beste {{ voornaam }} {{ achternaam }},<br />
                <br />
                Welkom bij Trusted Accountant. Doel van deze Personal Quick Scan
                is om je te laten ervaren wat de gebruiksvriendelijkheid van
                deze app is. Deze app zetten we in om generieke informatie via
                vraag en antwoord toepasbaar te maken op de situatie van de
                klant. Hierdoor ervaart de klant deze informatie als
                persoonlijker, directer en waardevoller. Positieve effecten zijn
                dat de klant meer awareness krijgt, beter inzicht in het
                vraagstuk en tot betere besluitvorming komt. Of beter voorbereid
                het volgende gesprek met je aangaat.<br /><br />

                Aangezien je deze app in je rol als partner/leidinggevende in
                een accountantskantoor doorneemt, maken we van de gelegenheid
                gebruik om je een aantal vragen voor te leggen over de
                ontwikkelingen in de accountancy branche. Op deze wijze maak je
                zowel kennis met de verschillende vraag- en antwoordvormen die
                we kunnen inzetten als met onze visie als serviceprovider van
                accountantskantoren.<br /><br />
                Wil je deze app bekijken in Dark Mode, dan kan je bovenin rechts
                dit activeren. <br /><br />
                <br /><br />Hartelijke groeten,<br /><br />
                {{ voornaam_acc }} {{ achternaam_acc }}<br /><br />
                Met de knop Verder start je met de vragen
              </v-card-text>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-12 ">
          <div class="row mt-8">
            <div class="col-3"></div>
            <div class="col-3">
              <Knopverder :on-click="next"></Knopverder>
            </div>
            <div class="col-3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Toolbar from "@/components/core/Toolbar";
import Knopverder from "@/components/knopverder";

export default {
  name: "Module1.vue",
  components: { Knopverder, Toolbar },
  data() {
    return {
      goDark: false,
      voornaam: null,
      achternaam: null,
      voornaam_acc: null,
      achternaam_acc: null
    };
  },
  methods: {
    next() {
      // eslint-disable-next-line
      console.log("Verzenden");
      this.$router.replace("/topica");
    }
  },

  mounted() {
    // eslint-disable-next-line
    console.log("App mounted!");
    this.token = JSON.parse(localStorage.getItem("token"));
    this.voornaam = JSON.parse(localStorage.getItem("voornaam"));
    this.achternaam = JSON.parse(localStorage.getItem("achternaam"));
    this.voornaam_acc = JSON.parse(localStorage.getItem("voornaam_acc"));
    this.achternaam_acc = JSON.parse(localStorage.getItem("achternaam_acc"));
  }
};
</script>

<style scoped>
.embed-container {
  width: 100% !important;
}

@media (min-width: 200px) {
  p {
    font-size: 0.8em;
  }

  h1 {
    font-size: 0.9em;
    line-height: 1.5;
  }

  h2 {
    font-size: 1.6em;
    line-height: 1.5;
  }

  h3 {
    font-size: 1.4em;
    line-height: 1.5;
  }
}

@media (min-width: 768px) {
  p {
    font-size: 1em;
    line-height: 1.5;
  }

  h1 {
    font-size: 1.2em;
    line-height: 1.5;
  }

  h2 {
    font-size: 2em;
    line-height: 1.5;
  }

  h3 {
    font-size: 1.7em;
    line-height: 1.5;
  }
}

@media (min-width: 1200px) {
  p {
    font-size: 1.2em;
    line-height: 1.5;
  }

  h1 {
    font-size: 1.5em;
    line-height: 1.5;
  }

  h2 {
    font-size: 2.3em;
    line-height: 1.5;
  }

  h3 {
    font-size: 2em;
    line-height: 1.5;
  }

  .iframe-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
