<template>
  <div>
    <toolbar></toolbar>
    <div class="container breedte">
      <div id="element1" class="row" v-if="stap === 1">
        <div class="col-sm-6 col-12 ">
          <div class="v-sheet pl-0 pr-0">
            <v-card-title class="primary--text">
              DE UITDAGING
            </v-card-title>
            <v-card-text class=" body-1 spec--text">
              De accountancy branche heeft te maken met een grote IT-inspanning
              om het primaire proces verder te stroomlijnen. Tegelijkertijd
              nemen de mogelijkheden om nieuwe omzet binnen het jaarwerk te
              realiseren af. Dat betekent dat naar nieuwe kansen wordt gezocht,
              bijvoorbeeld in adviesproducten buiten de accountancy en
              fiscaliteit. Dit levert echter, mede gezien de krapte op de
              arbeidsmarkt, nieuwe uitdagingen op voor de kantoororganisatie.<br /><br />
              In hoeverre ben je het eens met de volgende stelling:
            </v-card-text>
          </div>
        </div>
        <div class="col-sm-6 col-12 ">
          <div class=" pl-0 pr-5 " style="min-height: 268px;">
            <div class="container ml-0 pl-1">
              <v-card-title class="primary--text">
                Op langere termijn is de belangrijkste uitdaging om de cultuur
                binnen het accountantskantoor aan te passen, zodat ruimte
                ontstaat voor vernieuwing en innovatie in de MKB adviespraktijk.
              </v-card-title>
              <ta-slider3 v-model="question_a" />
              <v-card-text class=" body-1 font-italic spec--text">
                Een slider werkt zeer intuïtief op tablets en smartphones. Je
                schuift met je vinger over het scherm naar de positie die past
                bij je antwoord. In het rapport komt dit resultaat terug als een
                cijfer tussen -100 (geheel mee oneens) en 100 (geheel mee eens).
              </v-card-text>
            </div>
            <div class="row mt-8">
              <div class="col-3"></div>
              <div class="col-3">
                <v-btn
                  large
                  color="primary"
                  @click="stapA"
                  v-scroll-to="{ el: '#element1' }"
                >
                  Verder
                </v-btn>
              </div>
              <div class="col-3"></div>
            </div>
          </div>
        </div>
      </div>
      <div id="element2" class="row" v-if="stap === 2">
        <div class="col-sm-6 col-12 ">
          <div class="v-sheet pl-0 pr-0">
            <v-card-title class="primary--text">
              AANTREKKELIJKHEID ALS WERKGEVER
            </v-card-title>
            <v-card-text class=" body-1 spec--text">
              Door de krapte op de arbeidsmarkt is het lastig om goede mensen te
              werven en te behouden. Hierbij spelen niet alleen de primaire en
              secundaire arbeidsvoorwaarden een rol, maar ook de
              aantrekkelijkheid van de job. (Top)talenten verwachten dat ze
              uitgedaagd worden in de opdrachten die zij vervullen. Dat gaat
              naar hun mening verder dan het samenstellen van de jaarrekening.
              Ze willen ook aan de slag met technische innovaties zoals
              data-analyse of met nieuwe adviesconcepten.
            </v-card-text>
          </div>
        </div>
        <div class="col-sm-6 col-12 ">
          <div class=" pl-0 pr-5 " style="min-height: 268px;">
            <div class="container ml-0 pl-1">
              <v-card-title class="primary--text">
                Heeft jouw kantoor een beleid om (top)talenten deze uitdagingen
                te bieden, bijvoorbeeld een High Potentials Programma?
              </v-card-title>
              <div class="row">
                <div class="col-12 ml-5">
                  <v-form v-model="valid" ref="form_b" lazy-validation>
                    <v-radio-group
                      v-model="question_b"
                      :rules="radioRules"
                      required
                    >
                      <v-radio value="ke1" color="primary">
                        <template v-slot:label>
                          <div class="spec--text">
                            Ja
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="ke2" color="primary">
                        <template v-slot:label>
                          <div class="spec--text">
                            Nee
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                    <v-btn
                      color="primary"
                      @click="stapB"
                      v-scroll-to="{ el: '#element2' }"
                    >
                      Verder</v-btn
                    >
                  </v-form>
                </div>
                <v-card-text class=" body-1 font-italic spec--text">
                  Een zogenaamde radiobutton, hier als Ja en Nee, kan op
                  verschillende manieren worden ingezet. In dit geval dwingen we
                  je om een feitelijk antwoord te geven. Dit soort vragen kunnen
                  helpen om een goede inventarisatie te maken.
                </v-card-text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="element3" class="row" v-if="stap === 3">
        <div class="col-sm-6 col-12 ">
          <div class="v-sheet pl-0 pr-0">
            <div class="container ml-0 pl-1">
              <v-card-title class="primary--text">
                INNOVATIE
              </v-card-title>
              <v-card-text class=" body-1 spec--text">
                Dit is een belangrijke prikkel om processen sneller en
                efficiënter te laten verlopen in de samenstellingspraktijk. De
                MKB adviespraktijk loopt daarin achter. Onze visie is dat
                stroomlijning van adviesprocessen leidt tot een hogere
                toegevoegde waarde voor de klant. Wij voorzien namelijk dat de
                klant op termijn niet meer bereid zal zijn om voor stappen in
                het adviesproces te betalen, die ook door middel van technologie
                kunnen worden opgelost. Tegelijkertijd zien we dat ondernemers
                meer dan ooit zelf de regie willen houden over wat voor hen
                belangrijk en urgent is. Ze verwachten dat hun adviseurs hen
                daarin ondersteunen. Om deze redenen verwachten we dat de rol
                van IT in adviesprocessen de komende jaren sterk zal worden
                uitgebreid.
              </v-card-text>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-12 ">
          <div class=" pl-0 pr-5 " style="min-height: 268px;">
            <div class="container ml-0 pl-1">
              <v-card-title class="primary--text">
                Is dit voor jouw kantoor een belangrijk onderwerp?
              </v-card-title>
            </div>
            <div class="row mt-8">
              <div class="col-3"></div>
              <div class="col-3">
                <v-btn
                  large
                  color="primary"
                  @click="duimlaagC"
                  v-scroll-to="{ el: '#element3' }"
                >
                  <v-icon dark class="white--text">
                    mdi-thumb-down
                  </v-icon>
                </v-btn>
              </div>
              <div class="col-3">
                <v-btn
                  large
                  color="secondary"
                  @click="duimopC"
                  v-scroll-to="{ el: '#element3' }"
                >
                  <v-icon dark class="white--text">
                    mdi-thumb-up
                  </v-icon>
                </v-btn>
              </div>
              <v-card-text class=" body-1 font-italic spec--text">
                Met thumps up of down kan je de belangstelling voor een
                onderwerp of vraagstuk op een speelse, moderne manier peilen.
              </v-card-text>
            </div>
          </div>
        </div>
      </div>
      <div id="element4" class="row" v-if="stap === 4">
        <div class="col-sm-6 col-12 ">
          <div class="container ml-0 pl-1">
            <v-card-title class="primary--text">
              PROCESOPTIMALISATIE
            </v-card-title>
            <div class="col-3 px-12">
              <v-btn large color="secondary" @click="createPPT">
                PPTX
              </v-btn>
            </div>
            <v-card-text class=" body-1 spec--text">
              Met bovenstaand knop kan je een PowerPoint samenstellen. In je
              eigen online platform. Kan je van elke QuickScan een op maat
              gemaakte PowerPoint samenstellen, vaak binnen 2 tot 3 seconden.
              Dus geen namen, datum etc. meer wijzigen. Maar een presentatie op
              maat voor jouw klant! <br />
              <br />De PowerPoint geeft een overzicht van de gestelde vragen en
              jouw antwoorden. Hiermee heb je een belangrijke tool in handen
              voor het klantgesprek. Startpunt is de input van de klant. Aan elk
              antwoord kan een set van vervolgvragen worden gekoppeld. Zo help
              je de klant om zijn gedachten verder vorm te geven. Waarmee je
              bijdraagt aan het inzicht in het vraagstuk en waardoor de
              bereidheid toe om met het onderwerp aan de slag te gaan
              toeneemt.<br />
              <br />
              Het levert een belangrijke bijdrage aan de gehele customer
              experience en biedt tegelijkertijd snelheid, uniformiteit en
              compliancy in het adviesproces.
            </v-card-text>
          </div>
        </div>
        <div class="col-sm-6 col-12 ">
          <div class=" pl-0 pr-5 " style="min-height: 268px;">
            <div class="container ml-0 pl-1">
              <v-card-title class="primary--text">
                Welke bezwaren verwacht je binnen het kantoor als je deze
                werkwijze zou introduceren? Je kunt meerdere opties kiezen.
              </v-card-title>
              <v-checkbox v-model="question_d" required>
                <template v-slot:label>
                  <div class="spec--text">
                    Lage veranderingsbereidheid ("weer een nieuw systeem dat we
                    niet gaan gebruiken")
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox v-model="question_e" required>
                <template v-slot:label>
                  <div class="spec--text">
                    Past niet bij onze methode van werken ("not invented
                    here-syndrome")
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox v-model="question_f" required>
                <template v-slot:label>
                  <div class="spec--text">
                    Elke klant is uniek, daar past geen uniforme werkwijze bij
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox v-model="question_g" required>
                <template v-slot:label>
                  <div class="spec--text">
                    Data bescherming en privacy
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox v-model="question_h" required>
                <template v-slot:label>
                  <div class="spec--text">
                    Andere reden, namelijk
                  </div>
                </template>
              </v-checkbox>
              <div v-if="question_h">
                <v-textarea
                  outlined
                  shaped
                  placeholder="Andere reden"
                  v-model="question_i"
                  :counter="200"
                  required
                />
              </div>
              <v-btn
                color="primary"
                @click="duimopD"
                kv-scroll-to="{ el: '#element4'}"
                >Verder</v-btn
              >
            </div>
            <v-card-text class=" body-1 font-italic spec--text">
              Checkboxen zijn een goed hulpmiddel om de motieven van de klant te
              onderzoeken. Door verschillende motieven van tevoren te noemen,
              wordt het ook mogelijk om dit te vergelijken met de resultaten van
              andere klanten.
            </v-card-text>
          </div>
        </div>
      </div>
      <div id="element5" class="row" v-if="stap === 5">
        <div class="col-sm-6 col-12 ">
          <div class="v-sheet pl-0 pr-0">
            <div class="container ml-0 pl-1">
              <div>
                <vimeo-player
                  :video-id="490769243"
                  class="embed-container ml-0"
                  :options="{ responsive: true }"
                ></vimeo-player>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-12 ">
          <div class=" pl-0 pr-5 " style="min-height: 268px;">
            <div class="container ml-0 pl-1">
              <v-card-title class="primary--text">
                GEBRUIK VAN ANIMATIES
              </v-card-title>
              <v-card-text class=" body-1 spec--text">
                Bijgaande animatie geeft binnen enkele minuten een volledig
                inzicht in een onderwerp. Mensen zijn vaak visueel ingesteld en
                vinden het daarom makkelijker om iets te bekijken dan een heel
                artikel te lezen. Beelden zeggen meer dan 1.000 woorden. Wij
                gebruiken animaties veelvuldig om een boodschap snel en helder
                over te brengen. Animaties kunnen worden ingezet voor vrijwel
                elk onderwerp.
              </v-card-text>
              <v-card-title class="primary--text">
                Passen animaties bij de communicatievoorkeur van jouw klanten?
              </v-card-title>
            </div>
            <div class="row mt-8">
              <div class="col-3"></div>
              <div class="col-3">
                <v-btn
                  large
                  color="primary"
                  @click="duimlaagE"
                  v-scroll-to="{ el: '#element5' }"
                >
                  <v-icon dark class="white--text">
                    mdi-thumb-down
                  </v-icon>
                </v-btn>
              </div>
              <div class="col-3">
                <v-btn
                  large
                  color="secondary"
                  @click="duimopE"
                  v-scroll-to="{ el: '#element5' }"
                >
                  <v-icon dark class="white--text">
                    mdi-thumb-up
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="element6" class="row" v-if="stap === 6">
        <div class="col-sm-6 col-12 ">
          <div class="v-sheet pl-0 pr-0">
            <div class="container ml-0 pl-1">
              <v-img contain src="img/pcm.jpg" max-height="600" alt="logo" />
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-12 ">
          <div class=" pl-0 pr-5 " style="min-height: 268px;">
            <div class="container ml-0 pl-1">
              <v-card-title class="primary--text">
                WAT VIND JE VAN PERSONAL CONTENT MARKETING?
              </v-card-title>
              <v-card-text class=" body-1 spec--text">
                Personal Content Marketing biedt tal van mogelijkheden voor jouw
                kantoor. Denk aan het delen van kennis op een moderne wijze,
                waarbij de klant zelf de regie heeft over hoe en wanneer hij
                deze kennis gaat doornemen en toepassen. Je kunt voorafgaand aan
                een klantgesprek de interesse over verschillende vraagstukken
                peilen bij de klant. Je kunt social media campagnes koppelen aan
                landingpages. Dit alles in de huisstijl van je kantoor en via
                een eigen online platform.
                <br /><br />
                Hoeveel sterren geef jij Personal Content Marketing?
              </v-card-text>
            </div>
            <div class="row mt-8">
              <div class="col-3"></div>
              <v-rating
                hover
                length="5"
                size="64"
                v-model="question_k"
                half-increments
              ></v-rating>
              <div class="col-3">
                <v-btn
                  large
                  color="secondary"
                  @click="duimopF"
                  v-scroll-to="{ el: '#element5' }"
                  >Verder
                </v-btn>
              </div>
            </div>
            <v-card-text class=" body-1 font-italic spec--text">
              Een rating is goed bruikbaar om de klant te vragen om een
              totaalbeoordeling of waardering.
            </v-card-text>
          </div>
        </div>
      </div>
      <div id="element7" class="row" v-if="stap === 7">
        <div class="col-sm-6 col-12 ">
          <div class="v-sheet pl-0 pr-0">
            <div class="container ml-0 pl-1">
              <v-img
                contain
                src="img/img_360.png"
                max-height="600"
                alt="logo"
              />
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-12 ">
          <div class=" pl-0 pr-5 " style="min-height: 268px;">
            <div class="container ml-0 pl-1">
              <div class="primary--text">
                <h3>Einde</h3>
              </div>

              <v-card-text class=" body-1 spec--text">
                Je bent aan het einde gekomen van deze korte Personal Quick
                Scan. Je hebt nu kennis opgedaan over onze visie, verschillende
                vraagvormen ingevuld en ervaring opgedaan met de
                gebruikersvriendelijkheid. We hebben qua inhoud beperkt tot een
                animatie en teksten, maar het is heel goed mogelijk om andere
                media zoals foto's te gebruiken.

                <br /><br />
                Als je klaar bent met invullen, druk je op de knop Verzend. Je
                ontvangt dan een rapport (PDF-format) in je e-mailbox. De app
                sluit zich vanzelf.
                <br /><br />
                Dank voor het invullen.
                <br /><br />
                Namens het team achter Trusted Accountant<br />
                {{ voornaam_acc }} {{ achternaam_acc }}
              </v-card-text>
            </div>
            <div class="row mt-8">
              <div class="col-3"></div>
              <div class="col-3">
                <v-btn
                  large
                  color="primary"
                  @click="eind"
                  v-scroll-to="{ el: '#element7' }"
                >
                  Einde
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Toolbar from "@/components/core/Toolbar";
import TaSlider3 from "@/components/slider/ta-slider3";

export default {
  name: "Module1.vue",
  components: {
    TaSlider3,

    Toolbar
  },
  data() {
    return {
      bedrijfsnaam: "",
      voornaam: null,
      achternaam: null,
      voornaam_acc: null,
      achternaam_acc: null,
      stap: 1,
      question_a: 0,
      question_b: "",
      question_c: false,
      question_d: false,
      question_e: false,
      question_f: false,
      question_g: false,
      question_h: false,
      question_i: "",
      question_j: false,
      question_k: 2.5,
      radioRules: [
        v => !!v || "Je kan niet verder zonder eerst een keuze te maken."
      ],
      goDark: false,
      valid: true,
      status1: false,
      token: null
    };
  },
  methods: {
    createPPT() {
      let colorA = "003895";
      let koptekst2 = "PowerPoint";
      let koptekst3 = "De uitdaging";
      let koptekst4 = "Aantrekkelijkheid als werkgever";
      let koptekst5 = "Mogelijkheden";
      let koptekst6 = "Mogelijkheden II";
      let koptekst15 = "Meer weten";

      // eslint-disable-next-line no-unused-vars
      let gTitelplaats = { x: 0.25, y: 0.7, w: 9.5, h: 0.46, align: "left" };
      // eslint-disable-next-line no-unused-vars
      let gTitel = {
        fontFace: "Arial",
        fontSize: 33,
        color: colorA,
        valign: "top"
      };
      let opsom1 = {
        bullet: false,
        fontSize: 12,
        fontFace: "Calibri",
        breakLine: true
      };
      let opsom2 = {
        bullet: false,
        fontSize: 12,
        bold: true,
        fontFace: "Calibri",
        breakLine: true
      };
      let opsom3 = {
        bullet: { indent: 10 },
        fontSize: 12,
        bold: false,
        fontFace: "Calibri",
        breakLine: true
      };
      let opsom4 = {
        bullet: { type: "number", indent: 10 },
        fontSize: 12,
        bold: false,
        fontFace: "Calibri",
        breakLine: true
      };
      let tekstblok = {
        x: 0.5,
        y: 1.26,
        w: 8.56,
        h: 3.88,
        fontFace: "Calibri",
        fontSize: 12,
        valign: "top"
      };
      let tekstblok2 = {
        x: 0.5,
        y: 1.26,
        w: 8.56,
        h: 2.12,
        fontFace: "Calibri",
        fontSize: 12,
        valign: "top"
      };
      let tekstblok3 = {
        x: 0.15,
        y: 1.25,
        w: 5.94,
        h: 3.88,
        fontFace: "Calibri",
        fontSize: 12,
        valign: "top"
      };
      // eslint-disable-next-line no-undef
      let pptx = new pptxgen();
      pptx.defineSlideMaster({
        title: "MASTER_SLIDE",
        bkgd: "FFFFFF",
        objects: [
          {
            text: {
              text: "-TRUSTED ACCOUNTANT-",
              options: {
                x: 0.6,
                y: 5.23,
                w: 6.5,
                h: 0.33,
                fontFace: "Calibri",
                fontSize: 6.8,
                charSpacing: 5,
                color: "9D968D",
                valign: "top"
              }
            }
          },
          {
            image: {
              x: 0,
              y: 5.03,
              w: 4.79,
              h: 0.3,
              path: "./img/kleurbalk.png"
            }
          }
        ],
        slideNumber: {
          x: 0.42,
          y: 5.23,
          fontFace: "Calibri",
          fontSize: 6.8,
          color: "9D968D"
        }
      });

      let slide1 = pptx.addSlide();
      slide1.addImage({
        path: "./img/start.jpg",
        x: 0,
        y: 0,
        w: "100%",
        h: "100%"
      });

      slide1.addShape(pptx.shapes.RECTANGLE, {
        x: 6.63,
        y: 0,
        w: 3.37,
        h: 5.63,
        fill: { color: "003895", transparency: "50" }
      });
      slide1.addText("The Future of Advice", {
        x: 2.52,
        y: 2.18,
        w: 4.11,
        h: 0.66,
        fontSize: 33,
        fontFace: "Calibri",
        color: "ffffff",
        align: "center",
        valign: "bottom"
      });
      slide1.addImage({
        path: "./img/logo_light.png",
        x: 7.27,
        y: 0.35,
        w: 2.09,
        h: 2.46
      });
      slide1.addText(this.bedrijfsnaam, {
        x: 7.25,
        y: 2.3,
        w: 2.3,
        h: 0.35,
        fontSize: 13.5,
        fontFace: "Calibri",
        color: "ffffff",
        align: "left",
        valign: "bottom"
      });
      slide1.addText(this.voornaam + " " + this.achternaam, {
        x: 7.25,
        y: 3.9,
        w: 2.3,
        h: 0.35,
        fontSize: 13.5,
        fontFace: "Calibri",
        color: "ffffff",
        align: "left",
        valign: "bottom"
      });
      let slide2 = pptx.addSlide("MASTER_SLIDE");
      slide2.addText([{ text: koptekst2, options: gTitel }], gTitelplaats);
      slide2.addText(
        [
          {
            text: "Beste " + this.voornaam + " " + this.achternaam,
            options: opsom1
          },
          {
            text: "",
            options: opsom1
          },
          {
            text:
              "Je bent de app aan het invullen om te ervaren hoe Personal Content Marketing werkt. Deze PowerPoint is een essentieel onderdeel van de customer experience. Het maakt een naadloze overgang mogelijk tussen de app, de input van de klant in de app en het adviesgesprek. Hoe dat werkt leggen we hieronder uit.",
            options: opsom1
          },
          {
            text: "",
            options: opsom1
          },
          {
            text:
              "In de online omgeving is de input vanuit het PCM-instrument beschikbaar. De relatiebeheerder maakt in zijn gespreksvoorbereiding een PowerPoint presentatie op basis van deze input. Binnen enkele seconden is deze in de huisstijl en volledig ingevuld beschikbaar. De input van de klant kan via de PowerPoint eenvoudig bespreekbaar worden gemaakt in het gesprek. Ook biedt de PowerPoint per vraagstuk verdieping door de juiste vervolgvragen te vermelden. Dat maakt dat de relatiebeheerder een krachtig instrument heeft om de klantbehoeften verder te inventariseren.",
            options: opsom1
          },

          {
            text: "",
            options: opsom1
          },
          {
            text:
              "Belangrijke voordelen van deze methode zijn de positieve klantbeleving, snelheid en efficiency van het adviesproces en de compliancy m.b.t. inhoud. De PowerPoint is een belangrijk instrument in de toolbox van de relatiebeheerder.",
            options: opsom1
          },
          {
            text: "",
            options: opsom1
          },
          {
            text:
              "In de volgende sheets een aantal voorbeelden van hoe dit kan werken.",
            options: opsom1
          }
        ],
        tekstblok
      );
      let slide3 = pptx.addSlide("MASTER_SLIDE");
      slide3.addText([{ text: koptekst3, options: gTitel }], gTitelplaats);
      slide3.addText(
        [
          {
            text: "De eerste vraag in de test app betrof:",
            options: opsom1
          },
          {
            text:
              "Op langere termijn is de belangrijkste uitdaging om de cultuur binnen het accountantskantoor aan te passen, zodat ruimte ontstaat voor vernieuwing en innovatie in de MKB adviespraktijk.",
            options: opsom1
          },
          {
            text: "",
            options: opsom1
          },
          {
            text:
              "Het antwoord kon je geven door middel van een slider met als uitersten “Geheel mee oneens” en “Geheel mee eens”. De positie die je aangaf op de slider was " +
              this.question_a +
              "Deze waarde noemen wij de Gevoelscore.",
            options: opsom1
          },

          {
            text: "",
            options: opsom1
          },
          {
            text:
              "In de terugkoppeling aan de respondent kan je deze Gevoelscore koppelen aan een aantal klassen. Aan deze klassen kunnen vervolgens teksten en vervolgvragen worden gekoppeld. Bijvoorbeeld:",
            options: opsom1
          }
        ],
        tekstblok2
      );
      let rows3 = [];
      rows3.push([
        {
          text: "Gevoelscore",
          options: { bold: true }
        },
        {
          text: "Tekst en vervolgvraag",
          options: { bold: true }
        }
      ]);
      rows3.push([
        {
          text: "-100 tot -75"
        },
        {
          text:
            "U bent het niet eens met de stelling. Welke uitdagingen zijn voor u belangrijker?"
        }
      ]);
      rows3.push([
        {
          text: "-75 tot -25"
        },
        {
          text:
            "U verwacht niet dat cultuur niet het belangrijkste issue is. Hoe verhoudt dit vraagstuk zich t.o.v.andere uitdagingen?"
        }
      ]);
      rows3.push([
        {
          text: "-25 tot +25"
        },
        {
          text:
            "U twijfelt of cultuur de belangrijkste uitdaging is. Welke afwegingen maakt u hierbij?"
        }
      ]);
      rows3.push([
        {
          text: "25 tot 75"
        },
        {
          text:
            "U verwacht dat cultuur het belangrijkste issue zal zijn. Welke elementen van de huidige cultuur wilt u behouden?"
        }
      ]);
      rows3.push([
        {
          text: "75 tot 100"
        },
        {
          text:
            "U bent het eens met de stelling. Welke stappen heeft u genomen om deze uitdaging aan te gaan?"
        }
      ]);

      slide3.addTable(rows3, {
        x: 0.5,
        y: 3.15,
        w: 9.3,
        colW: [1, 8.3],
        fontSize: "12",
        fontFace: "Calibri",
        color: "000000"
      });
      let slide4 = pptx.addSlide("MASTER_SLIDE");
      slide4.addText([{ text: koptekst4, options: gTitel }], gTitelplaats);
      slide4.addText(
        [
          {
            text: "De tweede vraag in de test app betrof:",
            options: opsom1
          },
          {
            text:
              "Heeft uw kantoor een beleid om (top)talenten deze uitdagingen te bieden, bijvoorbeeld een High Potentials Programma?",
            options: opsom1
          },
          {
            text: "",
            options: opsom1
          },
          {
            text: "Het antwoord kon je geven door middel van Ja of Nee.",
            options: opsom1
          },
          {
            text: "",
            options: opsom1
          },
          {
            text:
              "Afhankelijk van het antwoord kan je hier verdere informatie en vervolgvragen aan koppelen. Bijvoorbeeld bij het antwoord Nee:",
            options: opsom1
          },

          {
            text: "",
            options: opsom1
          },
          {
            text:
              "Mocht u overwegen een High Potential programma op te zetten, dan zijn dit een aantal belangrijke stappen:",
            options: opsom1
          },
          {
            text: "Campus recruitment betekent ….",
            options: opsom4
          },
          {
            text: "Mentoring betekent ….",
            options: opsom4
          },
          {
            text:
              "Werkomgeving voor high potentials creëren, dit zijn de belangrijkste tips",
            options: opsom4
          },
          {
            text: "",
            options: opsom1
          },
          {
            text: "Bij het antwoord Ja, kan je vervolgvragen koppelen:",
            options: opsom1
          },
          {
            text: "Wanneer bent u gestart?",
            options: opsom3
          },
          {
            text:
              "Wat zijn uw ervaringen met het opzetten en uitvoeren van het programma?",
            options: opsom3
          },
          {
            text: "Over welke elementen in het programma bent u tevreden?",
            options: opsom3
          },
          {
            text:
              "Wat zijn de resultaten tot nu qua ontwikkeling en binding van toptalenten?",
            options: opsom3
          },
          {
            text: "et cetera",
            options: opsom3
          }
        ],
        tekstblok
      );

      let slide5 = pptx.addSlide("MASTER_SLIDE");
      slide5.addText([{ text: koptekst5, options: gTitel }], gTitelplaats);
      slide5.addText(
        [
          {
            text:
              "Personal Content Marketing biedt tal van mogelijkheden om kennis met klanten te delen.",
            options: opsom1
          },
          {
            text: "",
            options: opsom1
          },
          {
            text:
              "Het kan ingezet worden vanuit verschillende communicatiedoelen, van het verhogen van awareness tot het aanzetten tot actie bij de klant. Om de interesse in een vraagstuk te peilen of alle benodigde informatie voor het adviesgesprek te krijgen.",
            options: opsom1
          },
          {
            text: "",
            options: opsom1
          },
          {
            text:
              "De keuze met betrekking tot communicatiemiddel kan eveneens variëren. Het online platform werkt met animaties, expert-video’s, enquêtes, white papers, PowerPoint presentaties, documenten, etc.",
            options: opsom1
          },

          {
            text: "",
            options: opsom1
          },
          {
            text:
              "Belangrijke voordelen van deze methode zijn de positieve klantbeleving, snelheid en efficiency van het adviesproces en de compliancy m.b.t. inhoud. De PowerPoint is een belangrijk instrument in de toolbox van de relatiebeheerder.",
            options: opsom1
          },
          {
            text: "",
            options: opsom1
          },
          {
            text:
              "We hebben instrumenten ontwikkeld voor verschillende communicatiekanalen zoals de adviseur rechtstreeks met de klant, collectieve acties naar een klantengroep, landingpages voor de corporate website en voor social media campagnes.",
            options: opsom1
          },
          {
            text: "",
            options: opsom1
          },
          {
            text: "Op de volgende pagina een animatie met een verdere uitleg.",
            options: opsom2
          }
        ],
        tekstblok
      );

      let slide6 = pptx.addSlide("MASTER_SLIDE");
      slide6.addText([{ text: koptekst6, options: gTitel }], gTitelplaats);
      slide6.addMedia({
        type: "video",
        path: "./video/videopcm.mp4",
        x: 0.5,
        y: 1.2,
        w: 3.84,
        h: 2.74
      });
      slide6.addText(
        "In deze animatie leer je meer over de verschillende mogelijkheden van Personal Content Marketing. Het geeft je ook een beeld van hoe krachtig animaties zijn om een boodschap over te dragen naar een doelgroep.",
        {
          x: 0.5,
          y: 4.3,
          w: 8.56,
          h: 0.9,
          fontSize: 12,
          fontFace: "Calibri",
          align: "left",
          valign: "top"
        }
      );
      let slide15 = pptx.addSlide("MASTER_SLIDE");
      slide15.addText([{ text: koptekst15, options: gTitel }], gTitelplaats);
      slide15.addShape(pptx.shapes.RECTANGLE, {
        x: 6.36,
        y: 0,
        w: 3.64,
        h: 5.63,
        fill: { color: "003478" }
      });

      slide15.addText(
        [
          {
            text: "Trusted Accountant",
            options: {
              bullet: false,
              fontSize: 7.9,
              fontFace: "Calibri",
              bold: true,
              color: "FFFFFF",
              breakLine: true
            }
          },
          {
            text: "W. Vrijlandtstraat 8",
            options: {
              bullet: false,
              fontSize: 7.9,
              fontFace: "Calibri",
              color: "FFFFFF",
              breakLine: true
            }
          },
          {
            text: "3262 GN" + " Oud-Beijerland",
            options: {
              bullet: false,
              fontSize: 7.9,
              fontFace: "Calibri",
              color: "FFFFFF",
              breakLine: true
            }
          },
          {
            text: "",
            options: {
              bullet: false,
              fontSize: 7.9,
              fontFace: "Calibri",
              breakLine: true
            }
          },
          {
            text: "www.trustedaccountant.nl",
            options: {
              bullet: false,
              fontSize: 7.9,
              fontFace: "Calibri",
              color: "FFFFFF",
              breakLine: true
            }
          },
          {
            text: "",
            options: {
              bullet: false,
              fontSize: 7.9,
              fontFace: "Calibri",
              breakLine: true
            }
          },

          {
            text: "Arie Heerschap",
            options: {
              bullet: false,
              fontSize: 7.9,
              fontFace: "Arial",
              color: "FFFFFF",
              breakLine: true
            }
          },

          {
            text: "email: a.heerschap@trustedaccountant.nl",
            options: {
              bullet: false,
              fontSize: 7.9,
              fontFace: "Arial",
              color: "FFFFFF",
              breakLine: true
            }
          },
          {
            text: "telefoon: +31 6 1963 1986",
            options: {
              bullet: false,
              fontSize: 7.9,
              fontFace: "Arial",
              color: "FFFFFF",
              breakLine: true
            }
          }
        ],
        { x: 6.88, y: 2.45, w: 2.68, h: 2.68 }
      );
      slide15.addText(
        [
          {
            text:
              "“Wat maken we mogelijk met jouw inhoud en onze technologie?”",
            options: opsom1
          },
          {
            text: "",
            options: opsom1
          },
          {
            text:
              "Deze vraag stellen wij altijd centraal als we met accountantskantoren in gesprek gaan. Er is veel meer mogelijk dan dat je in eerste instantie verwacht. Onze taak is om jouw kennis te ontsluiten naar je klanten en adviesprocessen met behulp van onze technologie te stroomlijnen. De klant wordt voorzien in zijn communicatievoorkeuren, terwijl beter wordt voldaan aan de eisen van snelheid, efficiency, uniformiteit en compliancy. Onmisbare elementen in The Future of Advice.",
            options: opsom1
          },
          {
            text: "",
            options: opsom1
          },
          {
            text: "Nieuwsgierig? Laten we eens met elkaar sparren.",
            options: opsom1
          },

          {
            text: "",
            options: opsom1
          },
          {
            text: "Trustedaccountant.nl",
            options: opsom1
          },
          {
            text: "Eric Laaper en Arie Heerschap",
            options: opsom1
          },
          {
            text: "Telefoon/WhatsApp: (06) 1963 1986",
            options: opsom1
          },
          {
            text: "E-mail: arie.heerschap@trustedaccountant.nl",
            options: opsom1
          }
        ],
        tekstblok3
      );
      slide15.addImage({
        path: "./img/logo_light.png",
        x: 7.27,
        y: 0.35,
        w: 2.09,
        h: 2.46
      });

      let filename = "Presentatie_PQS_" + this.achternaam + ".pptx";
      // 4. Save the Presentation
      pptx.writeFile(filename);
    },

    stapA() {
      this.status1 = true;
      this.next_topic();
    },
    stapB() {
      if (this.$refs.form_b.validate()) {
        this.next_topic();
      }
    },

    duimlaagB() {
      this.topic_260 = false;
      this.next_topic();
    },
    duimlaagC() {
      this.topic_308 = false;
      this.next_topic();
    },
    duimlaagD() {
      this.topic_312 = false;
      this.next_topic();
    },
    duimlaagE() {
      this.question_j = false;
      this.next_topic();
    },
    duimlaagF() {
      this.topic_352 = false;
      this.next_topic();
    },
    duimlaagG() {
      this.topic_360 = false;
      this.next_topic();
    },
    duimlaagH() {
      this.topic_372 = false;
      this.next_topic();
    },
    duimlaagI() {
      this.topic_455 = false;
      this.next_topic();
    },
    duimlaagEind() {
      this.stap = 1;
    },

    duimopA() {
      this.status1 = true;
      this.topic_205 = true;
      this.next_topic();
    },
    duimopB() {
      this.topic_260 = true;
      this.next_topic();
    },
    duimopC() {
      this.topic_308 = true;
      this.next_topic();
    },
    duimopD() {
      this.topic_312 = true;
      this.next_topic();
    },
    duimopE() {
      this.question_j = true;
      this.next_topic();
    },
    duimopF() {
      this.topic_352 = true;
      this.next_topic();
    },
    duimopG() {
      this.topic_360 = true;
      this.next_topic();
    },
    duimopH() {
      this.topic_372 = true;
      this.next_topic();
    },
    duimopI() {
      this.topic_455 = true;
      this.next_topic();
    },
    duimopEind() {
      this.eind();
    },

    next_topic() {
      // eslint-disable-next-line
      console.log("Verzenden.......");
      let data = JSON.stringify({
        token: this.token,
        status1: this.status1,
        question_a: this.question_a,
        question_b: this.question_b,
        question_c: this.question_c,
        question_d: this.question_d,
        question_e: this.question_e,
        question_f: this.question_f,
        question_g: this.question_g,
        question_h: this.question_h,
        question_i: this.question_i,
        question_j: this.question_j,
        question_k: this.question_k
      });
      axios.post("/websitePQSupdate", data, {
        headers: {}
      });
      this.$swal({
        icon: "success",
        title: "Opgeslagen",
        text: "Uw keuze is veilig opgeslagen",
        showConfirmButton: false,
        timer: 1200
      })
        .then(response => {
          // eslint-disable-next-line
          console.log(response);

          this.stap++;
        })
        .catch(error => {
          // eslint-disable-next-line
          console.log(error);
          this.$swal({
            icon: "warning",
            title: "Oops er gaat iets fout",
            text: "Let op er gaat iets niet goed",
            footer:
              "Probeer het aub nogmaals mocht u problemen blijven ondervinden neem dan contact met ons op!"
          });
          this.$router.push({ name: "Start" });
        });
    },

    eind() {
      // eslint-disable-next-line
      console.log("Verzenden.......");
      let data = JSON.stringify({
        token: this.token,
        status1: this.status1,
        question_a: this.question_a,
        question_b: this.question_b,
        question_c: this.question_c,
        question_d: this.question_d,
        question_e: this.question_e,
        question_f: this.question_f,
        question_g: this.question_g,
        question_h: this.question_h,
        question_i: this.question_i,
        question_j: this.question_j,
        question_k: this.question_k
      });
      axios.post("/websitePQSeind", data, {
        headers: {}
      });
      this.$swal({
        icon: "success",
        title: "Opgeslagen",
        text: "Uw keuzes zijn veilig opgeslagen. u wordt nu uitgelogd.",
        showConfirmButton: false,
        timer: 1200
      })
        .then(response => {
          // eslint-disable-next-line
          console.log(response);
          localStorage.clear();
          localStorage.removeItem("token");
          localStorage.removeItem("tokkie");
          localStorage.removeItem("voornaam");
          localStorage.removeItem("achternaam");
          localStorage.removeItem("voornaam_acc");
          localStorage.removeItem("achternaam_acc");
          localStorage.removeItem("dark_theme");
          this.$router.push({ name: "Start" });
        })
        .catch(error => {
          // eslint-disable-next-line
          console.log(error);
          this.$swal({
            icon: "warning",
            title: "Oops er gaat iets fout",
            text: "Let op er gaat iets niet goed",
            footer:
              "Probeer het aub nogmaals mocht u problemen blijven ondervinden neem dan contact met ons op!"
          });
          this.$router.push({ name: "home" });
        });
    }
  },

  mounted() {
    // eslint-disable-next-line
    console.log("App mounted!");
    this.token = JSON.parse(localStorage.getItem("token"));
    this.voornaam = JSON.parse(localStorage.getItem("bedrijfsnaam"));
    this.voornaam = JSON.parse(localStorage.getItem("voornaam"));
    this.achternaam = JSON.parse(localStorage.getItem("achternaam"));
    this.voornaam_acc = JSON.parse(localStorage.getItem("voornaam_acc"));
    this.achternaam_acc = JSON.parse(localStorage.getItem("achternaam_acc"));
  }
};
</script>

<style scoped></style>
