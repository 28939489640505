import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

import Module1 from "../views/Module1";
import Start from "@/views/Start";
Vue.use(VueRouter);
import store from "../store";
import topicA from "@/views/topicA";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },

  {
    path: "/intro",
    name: "Intro",
    component: Module1,
    beforeEnter(to, from, next) {
      if (store.state.idToken) {
        next();
      } else {
        next("/");
      }
    }
  },
  {
    path: "/topica",
    name: "topicA",
    component: topicA,
    beforeEnter(to, from, next) {
      if (store.state.idToken) {
        next();
      } else {
        next("/");
      }
    }
  },
  {
    path: "*",
    component: Start,
    name: "Start"
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
