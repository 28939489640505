<template>
  <v-btn color="primary" @click="onClick">
    <slot>VERDER</slot>
  </v-btn>
</template>

<script>
export default {
  name: "knopverder",
  props: {
    onClick: {
      type: Function
    }
  }
};
</script>

<style scoped></style>
